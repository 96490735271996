import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { AdManagerStaticSlot, Callbacks, Initialized } from 'BaxterScript/types/Slot';
import { Config } from 'BaxterScript/types/Config';
import * as Strings from 'BaxterScript/helper/string/String';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import * as Html from 'BaxterScript/helper/browser/Html';
import * as Request from 'BaxterScript/helper/browser/Request';
import { DSA_STYLES, getDsaCompliantStaticAd } from 'BaxterScript/version/web/provider/admanager-static/DsaTemplate';
import { initializeTargeting } from 'BaxterScript/version/web/provider/InitializeTargeting';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { AdManagerStaticConfig } from 'BaxterScript/types/ProviderSlotConfig/AdManagerStatic';
import { AdManagerStaticProviderConfig } from 'BaxterScript/types/ProviderGlobalConfig/AdManagerStaticProviderConfig';

export const id: Providers = Providers.AD_MANAGER_STATIC;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const styles = (config: Config) => (webpackExclude(config) ? '' : DSA_STYLES);

const hasPath = () => {
  const slotProviderSettings = (globalThis.Baxter.config.slots?.providerSettings?.[id] || {}) as AdManagerStaticConfig;
  return slotProviderSettings.path !== undefined && slotProviderSettings.path !== null;
};

const getPathMapFor = (pageId, containerId, slotId) => {
  const config = globalThis.Baxter.config.slots?.providerSettings?.[id] as AdManagerStaticConfig;
  const path = getConfigById(config?.path, pageId, containerId, slotId) || {};
  return path.map || [];
};

export const initialize = (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Initialized<AdManagerStaticSlot> => {
  console.info('[SLOTS][ADMANAGER STATIC][INITIALIZE]', pageId, containerId, slotId, params);
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as AdManagerStaticConfig;
  const providerConfig = globalThis.Baxter.config.providers[id] as AdManagerStaticProviderConfig;
  const initializedTargeting = { targeting: { timestamp: new Date().getTime() } };
  initializeTargeting(
    initializedTargeting,
    providerSettings,
    providerConfig?.settings,
    pageId,
    containerId,
    slotId,
    params
  );
  let path: string | undefined;
  let core: Record<string, string> | undefined;
  if (hasPath()) {
    const pathMapping = getPathMapFor(pageId, containerId, slotId);
    const pathString = Strings.parseMap(pathMapping, params || {});
    const parts = pathString.split('/');
    path = parts.reduce((acc: string, part: string) => (part !== '' ? `${acc}/${part}` : acc), '');
    core = getConfigById(providerSettings?.path, pageId, containerId, slotId);
  }
  return {
    [id]: {
      providerConfig,
      config: {
        core,
      },
      initialized: {
        path,
        targeting: initializedTargeting.targeting as TargetingParams,
      },
      state: {},
    },
  };
};

export const create = (slot: AdManagerStaticSlot, callbacks: Callbacks): void => {
  console.info('[SLOTS][ADMANAGER STATIC][CREATE]', slot);
  // eslint-disable-next-line no-param-reassign
  slot[id].callbacks = callbacks;
};

const fetchCreative = async (url: string, slotId: string): Promise<string | null> => {
  let html;
  try {
    html = await Request.get(url);
    return html;
  } catch (err) {
    console.error('[SLOTS][ADMANAGER STATIC][CREATE] fetching ad error', err);
    newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_FETCH_AD_ERROR, {
      message: (err as Error).message,
      providerId: id,
      slotId,
    });
    return null;
  }
};

const populateCreativeWithTargeting = (html: string, targeting: TargetingParams): string => {
  let creative;
  Object.keys(targeting).forEach((key) => {
    const regex = new RegExp(`\\\${targeting.${key}\\}`, 'g');
    const value = Array.isArray(targeting) ? targeting.join(',') : (targeting ?? '').toString();
    creative = html.replace(regex, value);
  });
  return creative.replace(/\$\{.*?}/g, '');
};

const render = (slotInner: HTMLElement, slot: AdManagerStaticSlot, creativeHtml: string) => {
  try {
    const html = populateCreativeWithTargeting(creativeHtml, slot[id].initialized.targeting);
    const trackingDiv = document.createElement('div');
    trackingDiv.id = `native_ad_frame_${slot.id}`;
    trackingDiv.innerHTML = html;
    let creative;
    if (slot[id].providerConfig?.dsa?.enabled) {
      console.debug('[SLOTS][ADMANAGER STATIC][CREATE] use DSA template wrapper');
      creative = getDsaCompliantStaticAd({
        ...slot[id].providerConfig.dsa,
        staticCreative: trackingDiv,
        dsaLabelCss: slot[id].config.core?.dsaLabelCss ?? '',
        dsaIconColor: slot[id].config.core?.dsaIconColor ?? '',
        dsaIconBackgroundColor: slot[id].config.core?.dsaIconBackgroundColor ?? '',
        pageId: slot.pageId,
        containerId: slot.containerId,
        slotId: slot.id,
      });
    } else {
      console.debug('[SLOTS][ADMANAGER STATIC][CREATE] no DSA wrapper');
      creative = trackingDiv;
    }
    const scripts = [...creative.querySelectorAll('script')].map((js) => {
      const script = document.createElement('script');
      script.innerHTML =
        `try {\n${js.innerHTML}\n` +
        `} catch (err) {\n` +
        `  throw new Error('Advertising - Baxter | AccountId: ${globalThis.Baxter.config.accountId} | PageId: ${slot.pageId} | ContainerId: ${slot.containerId} | Slot: ${slot.id} | ' + err);\n` +
        `}`;
      script.type = 'text/javascript';
      script.async = true;
      js.remove();
      return script;
    });
    // eslint-disable-next-line no-param-reassign
    slotInner.innerHTML = '';
    slotInner.appendChild(creative);
    scripts.forEach((script) => slotInner.appendChild(script));
  } catch (e) {
    console.error('[SLOTS][ADMANAGER STATIC][CREATE] rendering html error', e);
    newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_RENDERING_ERROR, {
      message: 'rendering html error',
      pageId: slot.pageId,
      containerId: slot.containerId,
      providerId: id,
    });
    throw e;
  }
};

export const load = async (source: string, slots: AdManagerStaticSlot[] = []): Promise<void> => {
  console.info('[SLOTS][ADMANAGER STATIC][LOAD]', slots);
  await Promise.all(
    slots.map(async (slot) => {
      try {
        if (slot[id].state.alreadyRemoved) {
          console.debug('[SLOTS][ADMANAGER STATIC][LOAD] slot already removed', slot);
          newRelicMetrics.reportMetric(NewRelicMetric.ADMANAGER_STATIC_SLOT_ALREADY_REMOVED, { place: 'load' });
          return;
        }
        if (slot[id].initialized.path) {
          const url = `${slot[id].providerConfig.settings.assetPath}${slot[id].initialized.path}`;
          const creative = await fetchCreative(url, slot.id);
          if (slot[id].state.alreadyRemoved) {
            console.debug('[SLOTS][ADMANAGER STATIC][LOAD] slot already removed', slot);
            newRelicMetrics.reportMetric(NewRelicMetric.ADMANAGER_STATIC_SLOT_ALREADY_REMOVED, {
              place: 'afterFetchCreative',
            });
            return;
          }
          if (!creative) {
            slot[id].callbacks.slotRenderEndedCallback(source, slot, true);
          } else {
            render(slot.innerHtmlElement, slot, creative);
            slot[id].callbacks.slotRenderEndedCallback(source, slot, false);
          }
        } else {
          slot[id].callbacks.slotRenderEndedCallback(source, slot, true);
        }
      } catch (err) {
        console.error('[SLOTS][ADMANAGER STATIC][LOAD]', err);
        newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_LOAD_ERROR, { message: (err as Error).message });
      }
    })
  );
};

export const remove = (slots: AdManagerStaticSlot[]): void => {
  console.info('[SLOTS][ADMANAGER STATIC][REMOVE]', slots);
  slots.forEach((slot) => {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyRemoved = true;
    Html.clear(slot.innerHtmlElement);
  });
};
