import * as PrebidV2 from 'BaxterScript/version/web/provider/google/PrebidV2';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { GoogleImaSlot, GoogleImaSlotExternal, GoogleImaInitializedPrebid } from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Config } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import * as Strings from 'BaxterScript/helper/string/String';
import { GoogleImaConfig, GoogleImaPrebidConfig } from 'BaxterScript/types/ProviderSlotConfig/GoogleIma';
import { GoogleAdsProviderConfig } from 'BaxterScript/types/ProviderGlobalConfig/GoogleAdsProviderConfig';

export const id = Providers.GOOGLE_IMA;

export const webpackExclude = (config: Config): boolean => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleImaConfig;
  const prebidSettings = providerSettings.prebid;
  return !(
    (prebidSettings?._ && Object.values(prebidSettings._).some((item) => item?.enabled === true)) ||
    (prebidSettings && Object.values(prebidSettings).some((item) => item?.enabled === true))
  );
};

export const init = () => {
  console.info('[SLOTS][GOOGLEIMAPREBID][INIT]');
  PrebidV2.init();
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEIMAPREBID][DEPENDENCIES]');
  return PrebidV2.dependencies();
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEIMAPREBID][LOADED]');
  PrebidV2.loaded();
};

export const initialize = (
  providerConfig: GoogleAdsProviderConfig,
  slotConfig: GoogleImaPrebidConfig,
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): GoogleImaInitializedPrebid => {
  console.info('[SLOTS][GOOGLEIMAPREBID][INITIALIZE]', pageId, containerId, slotId, params);
  const sizes = PrebidV2.initialize(slotConfig);
  const { accountId, pathPrefix } = providerConfig.settings;
  const pathPrefixValue = Strings.parseMap(pathPrefix || [], params);
  const path = pathPrefixValue
    ? `/${accountId}/${pathPrefixValue}/${slotConfig.path}`
    : `/${accountId}/${slotConfig.path}`;
  return {
    sizes,
    path,
  };
};

const getPrebidExternal = (slot: GoogleImaSlot): GoogleImaSlotExternal => {
  const bids = PrebidV2.getBids(
    slot[id].initialized.targeting,
    slot[id].providerConfig,
    slot[id].config.prebid.bidders
  );
  // https://docs.prebid.org/dev-docs/adunit-reference.html
  return {
    mediaTypes: {
      video: {
        context: 'instream',
        playerSize: slot[id].initialized.prebid.sizes,
        mimes: ['video/mp4', 'video/webm', 'video/3gpp'],
        protocols: [1, 2, 3, 4, 5, 6, 7, 8],
        playbackmethod: [2],
        skip: 1,
      },
    },
    ...(bids.path
      ? {
          code: slot[id].initialized.prebid.path,
          bids: bids.path,
        }
      : {
          code: slot.innerId,
          bids: bids.id,
        }),
  };
};

export const create = (slot: GoogleImaSlot) => {
  console.info('[SLOTS][GOOGLEIMAPREBID][CREATE]', slot);
  const { pbjs } = window;
  // eslint-disable-next-line no-param-reassign
  slot[id].state.prebidExternal = getPrebidExternal(slot);
  console.debug('[SLOTS][GOOGLEIMAPREBID][CREATE]', slot, slot[id].state.prebidExternal);
  pbjs.que.push(() => {
    try {
      pbjs.addAdUnits(slot[id].state.prebidExternal);
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMAPREBID][CREATE]', e);
      newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
        command: '[GOOGLEIMAPREBIDCREATE]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const load = (source: string, slot: GoogleImaSlot, loadVideo) => {
  console.info('[SLOTS][GOOGLEIMAPREBID][LOAD]', slot);
  const { pbjs } = window;
  pbjs.que.push(() => {
    try {
      if (slot[id].state.alreadyRemoved) {
        console.debug('[SLOTS][GOOGLEIMAPREBID][LOAD] slot already removed', slot);
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'prebidLoad' });
        return;
      }
      pbjs.requestBids({
        adUnitCodes: [slot.innerId, slot[id].initialized.prebid.path],
        bidsBackHandler: (bids, timedOut) => {
          try {
            if (timedOut) {
              console.debug('[SLOTS][GOOGLEIMAPREBID][LOAD] timed out', bids);
              newRelicMetrics.reportMetric(NewRelicMetric.PREBID_TIMED_OUT);
            } else {
              console.debug('[SLOTS][GOOGLEIMAPREBID][LOAD] successfully returned', bids);
              newRelicMetrics.reportMetric(NewRelicMetric.PREBID_SUCCESSFULLY_RETURNED);
            }
            if (slot[id].state.alreadyRemoved) {
              console.debug('[SLOTS][GOOGLEIMAPREBID][LOAD] slot already removed', slot);
              newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, {
                place: 'prebidCallback',
              });
              return;
            }
            const adTagUrl = pbjs.adServers.dfp.buildVideoUrl({
              adUnit: slot[id].state.prebidExternal,
              params: {
                iu: slot[id].initialized.prebid.path,
                output: 'vast',
              },
            });
            pbjs.markWinningBidAsUsed({
              adUnitCode: slot[id].state.prebidExternal?.code,
            });
            loadVideo(source, slot, adTagUrl);
          } catch (e) {
            console.error('[SLOTS][GOOGLEIMAPREBID][LOAD]', e);
            newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
              command: '[GOOGLEIMAPREBIDLOAD][BIDSBACKHANDLER]',
              message: (e as Error).message,
            });
            throw e;
          }
        },
      });
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMAPREBID][LOAD]', e);
      newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
        command: '[GOOGLEIMAPREBIDCREATE]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const remove = (slots: GoogleImaSlot[] = []): void => {
  console.info('[SLOTS][GOOGLEADSPREBID][REMOVE]', slots);
  return PrebidV2.remove(
    slots.filter((slot) => slot[id].config.prebid.enabled).map((slot) => slot[id].initialized.prebid.path)
  );
};

export const setPageTargeting = (params: TargetingParams): void => {
  console.info('[SLOTS][GOOGLEADSPREBID][SETPAGETARGETING]', params);
  PrebidV2.setPageTargeting(params);
};

// eslint-disable-next-line import/no-default-export
export default {
  init,
  dependencies,
  loaded,
  initialize,
  create,
  load,
  remove,
  setPageTargeting,
};
